@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@ionic/core/css/core.css";
@import "@ionic/react/css/normalize.css";
@import "@ionic/react/css/structure.css";
@import "@ionic/react/css/typography.css";

html {
  -webkit-text-size-adjust: 100%;
}

.navBar {
  --background: var(--header-background);
  background-color: var(--header-background);
  --border-radius: 0px !important;
  border-radius: 0px !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  display: flex;
  align-self: center !important;
}

.navBarMenu {
  --background: var(--menu-background) !important;
  background-color: var(--menu-background) !important;
  /* height: 5; */
}

.tabsContainer {
  --overflow: hidden !important;
  /* box-shadow: inset -1px 0px 100px gold !important; */
}

.menuIcon {
  width: 44px;
  height: 44px;
}

ion-modal::part(handle) {
  background: var(--white) !important;
}

ion-menu::part(container) {
  --background: #000 !important;
}

ion-menu-button {
  min-height: 44px !important;
  max-height: 44px !important;
}

.menu-content-push {
  box-shadow: unset !important;
}

ion-toolbar {
  --border-width: 0 !important;
}

ion-header {
  background-color: var(--header-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: none !important;
  background-repeat: no-repeat !important;
}

ion-title {
  height: 44px;
  color: #ffffff !important;
}

ion-popover {
  --width: 300px !important;
}

ion-popover ion-datetime {
  --width: 300px !important;
  width: 300px !important;
}

ion-content {
  font-weight: var(--accessibility-bold-text) !important;
}

ion-button,
button {
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

ion-segment-button ion-label {
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

ion-back-button::part(text) {
  text-decoration: var(--accessibility-underline-links);
}

p {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  line-height: var(--accessibility-line-height) !important;
}

.header-back-buttons {
  color: #ffffff !important;
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: var(--white);
  /* --icon-font-size: 44px !important; */
  --icon-margin-end: -3px !important;
}

.iosHeader .navBar {
  --min-height: 44px !important;
  padding-top: max(var(--ion-statusbar-padding), var(--ion-safe-area-top)) !important;
}

.mdHeader .navBar {
  --min-height: 56px !important;
}

.filterNavBar {
  padding-left: 0 !important;
  padding-right: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

.page-background {
  --background: var(--page-background) !important;
}

.whiteBackground {
  --background: var(--white) !important;
}

ion-tab-bar,
ion-tab-button {
  background-color: #f7faf8;
  --border: 1px solid var(--tab-bar-border);
}

ion-modal ion-content {
  --background: var(--white);
}

ion-popover ion-toolbar {
  padding-top: 0 !important;
}

.contentContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
  padding-top: 24px;
  padding-bottom: 72px;
}

.contentContainerNoTabs {
  padding-bottom: calc(16px + var(--ion-safe-area-bottom)) !important;
}

ion-tab-bar {
  background-color: #f7faf8;
  /* box-shadow: none !important; */
  box-shadow: inset 0 0.5px 0 0 #adbdb1 !important;
}

ion-select-option {
  --inner-padding-start: 0 !important;
  --padding-start: 0 !important;
  padding-left: 0px !important;
}

.tabBarHidden {
  display: none;
}

.emptyTabButton {
  min-width: 56px !important;
  max-width: 56px !important;
  pointer-events: none;
}

.fabContainer {
  align-self: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.tabBarFab {
  /* margin-bottom: calc(2px + var(--ion-safe-area-bottom)) !important; */
  margin-bottom: calc(62px + var(--ion-safe-area-bottom)) !important;
  /* margin-right: calc(11px + var(--ion-safe-area-right)) !important; */
  margin-right: 11px !important;
}

.header-button {
  margin-left: -2px !important;
  min-height: 44px !important;
  max-height: 44px !important;
  margin-right: -2px !important;
}

.header-button::part(native) {
  min-height: 44px !important;
  max-height: 44px !important;
}

.headerIcon {
  width: 44px !important;
  height: 44px !important;
}

ion-tab-button {
  background-color: #f7faf8;
  border-top-style: solid;
  border-top-width: 0.5px;
  border-top-color: #adbdb1;
}

ion-tab-button.tab-selected {
  border-top: 2px solid var(--cta-blue) !important;
  border-top-style: solid !important;
  border-top-width: 2px !important;
  border-top-color: var(--cta-blue) !important;
  background: rgb(0, 122, 255) !important;
  background-size: 120px auto !important;
  background: linear-gradient(
    180deg,
    rgba(0, 122, 255, 0.10395408163265307) 0%,
    rgba(0, 122, 255, 0) 75%,
    rgba(0, 212, 255, 0) 100%
  ) !important;
}

.tab-selected {
  border-top: 2px solid var(--cta-blue) !important;
  border-top-style: solid !important;
  border-top-width: 2px !important;
  border-top-color: var(--cta-blue) !important;
  background: rgb(0, 122, 255) !important;
  background-size: 120px auto !important;
  background: linear-gradient(
    180deg,
    rgba(0, 122, 255, 0.10395408163265307) 0%,
    rgba(0, 122, 255, 0) 75%,
    rgba(0, 212, 255, 0) 100%
  ) !important;
}

.tabBarIconTop {
  height: 24px !important;
  width: 24px !important;
  object-fit: cover;
}

.tabBarIconStart {
  height: 24px !important;
  width: 24px !important;
  margin-right: 8px;
  object-fit: cover;
}

.tabBarLabelTop {
  font-size: 10px;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--grey-60);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.tabBarLabelActive {
  color: var(--cta-blue) !important;
  font-weight: var(--accessibility-bold-text-600) !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.tabBarLabelStart {
  font-size: 13px;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--grey-60);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

/* Media queries */

@media (max-width: 500px) {
  .contentContainer {
    padding-top: 16px !important;
  }
}

@media (max-width: 1012px) {
  .contentContainer {
    padding-left: calc(16px + var(--ion-safe-area-left));
    padding-right: calc(16px + var(--ion-safe-area-right));
  }
}

.loadingScreen {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: var(--header-background);
}

.loadingScreenText {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  margin-top: 16px;
}

.loadingScreenSpinner {
  --color: var(--white);
  width: 24px;
  height: 24px;
}

.fabPopover {
  --min-width: 325px !important;
  --max-width: 325px !important;
  --max-height: 408px !important;
  border-radius: 0px !important;
  --border-radius: 0px !important;
}

.fabPopover #ion-react-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px !important;
  --border-radius: 4px !important;
}

.fabPopover::part(backdrop) {
  background-color: var(--black) !important;
  --backdrop-opacity: 0.4 !important;
}

.fabPopover::part(arrow) {
  display: none !important;
}

.fabPopover::part(content) {
  --offset-y: -12px;
  border-radius: 4px !important;
  margin-bottom: 50px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.fabPopover:host {
  display: flex;
  flex-direction: column;
}

.fab-button-close-active {
  --background: var(--white) !important;
  --color: var(--cta-blue) !important;
}

.fabPopoverIonItem {
  --min-height: 48px;
  box-shadow: none !important;
  background-color: var(--white) !important;
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--white);
  --border-color: #ffffff !important;
  /* --border-st: inset 0 -1px 0 0 #dee2e6; */
  /* padding-left: 16px; */
  /* padding-right: 8px; */
  cursor: pointer;
}

.fabPopoverIonLabel {
  font-family: Inter;
  font-size: 16px;
  font-weight: var(--accessibility-bold-text-600) !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--cta-blue) !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
  margin-left: 16px;
}

.title-default {
  transition-duration: 0ms !important;
}

ion-buttons {
  transition-duration: 0ms !important;
}

@media (max-width: 997px) {
  .fabPopover::part(content) {
    --offset-x: -24.5px;
  }
}

@media (max-width: 981px) {
  .fabPopover::part(content) {
    --offset-x: -16px;
  }
}

@media (max-width: 375px) {
  .fabPopover::part(content) {
    --offset-x: 24.5px;
  }
}

@media (max-width: 374px) {
  .fabPopover::part(content) {
    --offset-x: 18px;
  }
}

#readContainer ol {
  @apply list-decimal list-outside pl-[revert];
}

#readContainer ul {
  @apply list-disc list-outside pl-[revert];
}

.iframe-container {
  position: relative;
  width: 100%;
  float: none;
  clear: both;
  height: 0;
  padding-bottom: 56.25%;
}

.iframe-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-color: #ffffff;
}
