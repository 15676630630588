/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Open Dyslexic";
  src: url("../assets/fonts/OpenDyslexic-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Open Dyslexic";
  src: url("../assets/fonts/OpenDyslexic-Bold.otf");
  font-weight: 700;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #007aff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ed242b;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Zeplin */
  --grey-00: #f8f9fa;
  --grey-10: #f1f3f5;
  --grey-20: #e8ecef;
  --grey-30: #dee2e6;
  --grey-40: #ced4da;
  --grey-50: #adb5bd;
  --grey-60: #868e96;
  --grey-70: #495057;
  --grey-80: #343a40;
  --grey-90: #212529;
  --portfolio-blue: #026f8a;
  --menu-header: #005f70;
  --cta-blue: #007aff;
  --cta-red: #ed242b;
  --orange-30: #fbd38d;
  --yellow-90: #744210;
  --white: #ffffff;
  --black: #000000;
  --search-background: #ffea00;
  --HCPC-background: #d34503;
  --menu-background: #005f70;
  --HART-orange: #cf8414;
  --HART-orange-dark: #c07c16;
  --ai-purple: #7555F6;

  /** Page */
  --page-background: var(--grey-20);

  /** Header */
  --header-background: #026f8a;

  /** Tab bar */
  --tab-bar-border: #f7faf8;
  /** Onboarding page */
  --onboarding-background: #026f8a;

  /* Text */
  --ion-font-family: "Inter";
}