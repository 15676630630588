ion-modal.fullscreenModal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.fullscreen {
  --width: 100%;
  --height: 100%;
}

.cancelButton {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  --color: var(--white);
}

.cancelButtonInactive {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  cursor: auto;
  color: rgba(255, 255, 255, 0.4) !important;
}

.cancelButton {
  width: auto !important;
  overflow: hidden;
}

.onboardingSubscriptionModalPage {
  --background: var(--portfolio-blue) !important;
}

.subscriptionContentContainer {
  padding-bottom: calc(80px + env(safe-area-inset-bottom)) !important;
}

.subscriptionFooter {
  display: flex !important;
  justify-content: center;
  box-shadow: inset 0 0.5px 0 0 #adb5bd;
  background-color: var(--portfolio-blue) !important;
  position: absolute !important;
  bottom: 0;
  opacity: 1 !important;
  width: 100% !important;
  z-index: 999999 !important;
  /* height: calc(60px + var(--ion-safe-area-bottom)) !important; */
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.subscriptionFooterContainer {
  display: flex !important;
  align-items: center;
  height: 80px;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
}

.subscriptionRefreshButton {
  width: 100%;
  height: 50px;
  max-width: 350px !important;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
  flex-shrink: 0;
  margin-top: 12px;
  align-self: center;
  --background: ;
  --background-hover: rgba(0, 0, 0, 0.1);
  --background-activated: rgba(0, 0, 0, 0.2);
  --background-focused: rgba(0, 0, 0, 0.2);
  --border-width: 1px;
  --border-color: var(--white);
  --border-style: solid;
}

.subscriptionWebRefreshSpinnerContainer {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscriptionWebRefreshSpinner {
  width: 14px;
  height: 14px;
}

.subscriptionContinueButtonText {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);
}

.dummyButton {
  display: none !important;
  /* width: 0px !important; */
}

.subscriptionContinueButton {
  width: 100%;
  height: 56px;
  max-width: 350px !important;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);
  flex-shrink: 0;

  --background: #ffffff;
  --background-hover: #e8ecef;
  --background-activated: #dee2e6;
  --background-focused: #dee2e6;
}

.subscriptionContinueButtonSubtext {
  font-size: 11px;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: var(--grey-60);
}

.onboardingSubscriptionHeader {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--white);
}

.onboardingSubscriptionSubheader {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--white);
  margin-top: 4px;
}

.subscriptionsBenefitsContainer {
  margin-top: 24px;
  margin-bottom: 36px;
}

.subscriptionsBenefits {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
}

.subscriptionsBenefitsIcon {
  width: 80px;
  height: 80px;
  margin-right: 12px;
}

.subscriptionsBenefitsHeader {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--white);
}

.subscriptionsBenefitsText {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--white);
}

.subscriptionsFAQ {
  display: flex;
  flex-direction: column;
}

.subscriptionsFAQHeader {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--white);
}

.subscriptionFAQText {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--white);
  margin-top: 12px;
}

.subscriptionFAQLink {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--white);
}

.subscriptionInformation {
  margin-top: 36px;
}

.subscriptionInformationHeader {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--white);
  margin-bottom: 8px;
}

.subscriptionInformationText {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--white);
}

.subscriptionsLoadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.closeIcon {
  width: 44px !important;
  height: 44px !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.subscriptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 12px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 55, 69, 0.3);
  background-color: var(--white);
  margin-bottom: 16px;
  cursor: pointer;
}

/* .subscriptionContainer:focus {
  background-color: #DEE2E6;
}

*/
.subscriptionContainer:active {
  background-color: #dee2e6;
}

/* .subscriptionContainer:hover {
  background-color: #E8ECEF;
} */

.subscriptionName {
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  margin-bottom: 4px;
}

.subscriptionPrice {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: #0994b6;
}

.subscriptionPeriod {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--grey-70);
}

.subscriptionDescription {
  font-size: 13px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.1px;
  color: var(--grey-70);
  margin-top: 4px;
}

.subscriptionTickBoxActive {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.subscriptionTickBox {
  height: 44px;
  width: 44px;
  margin-right: 2.5px;
}

.subscriptionErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  text-align: center;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--white);
}

#onboardingSubscriptionsModal {
  padding-top: max(var(--ion-safe-area-top), var(--ion-statusbar-padding)) !important;
  --border-radius: 0 !important;
}

.subscriptionSpinnerContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.subscriptionSpinner {
  --color: var(--white);
}

.onboardingLoginModalPage {
  --background: var(--white) !important;
}

.fullHeight {
  min-height: calc(100% - 76px) !important;
}

.onboardingLoginContent {
  align-items: unset;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 406px;
  width: 100%;
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  background-color: var(--white);
  min-height: calc(100% - 208px - 120px);
}

.onboardingLoginContentNoPadding {
  align-items: unset;
  padding-top: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 406px;
  width: 100%;
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  background-color: var(--white);
  min-height: calc(100% - 208px - 120px);
}

.onboardingLearnMoreContent {
  max-width: 980px !important;
}

.onboardingLearnMoreText {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.onboardingLoginHeader {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--grey-90);
  margin-bottom: 10px;
}

.onboardingLoginSubheader {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  margin-bottom: 4px;
}

.onboardingLoginLink {
  font-weight: bold;
  color: var(--cta-blue);
  /* text-decoration: none !important; */
  text-decoration: var(--accessibility-underline-links);
}

.onboardingPasswordRequirementsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.onboardingPasswordRequirementsTick {
  height: 17px;
  width: 17px;
  margin-right: 8px;
}

.onboardingLoginOptIn {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  margin-bottom: 16px;
}

.loginInputHeader {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-70);
  margin-top: 12px;
  margin-bottom: 6px;
}

.loginInputContainer {
  position: relative;
}

.loginInput {
  /* Container */
  width: 100%;
  height: 50px;
  margin: 6px 0 0;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  border: solid 0.5px var(--grey-50);
  background-color: var(--grey-10);

  /* Text */
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #000000;
}

.loginInputError {
  padding-right: 48px;
  border: solid 2px var(--cta-red);
}

.loginPasswordInput {
  padding-right: 70px;
}

.loginPasswordInputError {
  padding-right: 102px !important;
  border: solid 2px var(--cta-red);
}

.loginInput::placeholder {
  color: var(--grey-60);
}

.loginPasswordHidden {
  position: absolute;
  top: 6.5px;
  right: 0;
  height: 50px !important;
  line-height: 50px;
  margin-left: 12px;
  margin-right: 12px;
  z-index: 2;
  cursor: pointer;

  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.3px;
  text-align: right;
  color: var(--grey-70);
}

.loginPasswordHiddenErrors {
  right: 32px;
}

.loginInputErrorIcon {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 9px;
  right: 0;
}

.loginButtonContainer {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.loginButton {
  width: 100%;
  height: 56px;
  max-width: 374px;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
  --border-radius: 10px !important;
}

.loginButtonClear {
  width: 100%;
  height: 56px;
  max-width: 374px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--cta-blue);
  --background-hover: rgba(0, 0, 0, 0.1);
  --background-activated: rgba(0, 0, 0, 0.2);
  --background-focused: rgba(0, 0, 0, 0.2);
  --border-width: 1px;
  --border-color: var(--cta-blue);
  --border-style: solid;
}

.loginButtonDisabled {
  opacity: 0.4;
}

.loginDeletionButton {
  height: 56px;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
}

.addAttachmentButton {
  width: 100%;
  max-width: 374px;
  height: 56px;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
}

.loginForgotPasswordButton {
  width: 200px;
  height: 56px;

  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);
}

.loginErrorIcon {
  width: 44px;
  height: 44px;
  margin: 2px 0 2px 4px;
}

.loginErrorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: solid 2px var(--cta-red);
  background-color: #feeee7;
  margin-top: 3px;
}

.loginErrorMessageText {
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #710630;
  margin: 12px 12px 12px 4px;
}

.onboardingLoginLinkError {
  color: #710630;
}

.loginErrorIconInlineContainer {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.loginErrorIconInline {
  flex: none;
  width: 44px;
  height: 44px;
}

.loginErrorContainerInline {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.loginErrorMessageTextInline {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  letter-spacing: -0.2px;
  color: #710630;
  /* margin: 12px 12px 12px 4px; */
}

.loginFooter {
  background-color: var(--white);
  height: 98px !important;
  margin-top: auto;
  margin-bottom: calc(8px + var(--ion-safe-area-bottom));
  /* position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px; */
}

.loginFooterText {
  font-size: 13px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--grey-90);
  margin-bottom: 18px;
}

.loginFooterText a {
  color: var(--cta-blue);
  font-weight: var(--accessibility-bold-text-500);
}

.onboardingRadioGroup {
  margin-top: 14px;
}

.onboardingRadioContainer {
  display: flex;
  justify-content: center !important;
  /* --border-width: 0 !important; */
  --border-color: #adb5bd;
  /* --background: var(--white); */
  --ion-item-background: var(--white);
  --min-height: 56px;
  border-style: unset !important;
  --padding-start: 8px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
}

.onboardingRadioLabel {
  font-size: 20px !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  color: var(--grey-90) !important;
  background-color: transparent;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.onboardingDateHeader {
  font-size: calc(11px + var(--accessibility-font-size));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: normal;
  color: var(--grey-70);
  margin-top: 24px;
  margin-bottom: 6px;
}

.onboardingDateContainer {
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 8px;
  border: solid 0.5px var(--grey-50);
  background-color: var(--grey-10);
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}

.onboardingDateText {
  color: var(--black) !important;
}

.onboardingDateTextSelected {
  color: var(--cta-blue) !important;
}

.onboardingAttachmentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding-right: 12px;
  background-color: var(--grey-20);
  margin-bottom: -12px;
}

.onboardingAttachmentIcon {
  width: 44px;
  height: 44px;
  margin-right: -6px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.onboardingAttachmentFilename {
  width: 100%;

  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.onboardingAddAttachmentsSpinner {
  --color: var(--cta-blue);
  width: 24px;
  height: 24px;
}

.onboardingAttachmentCancelSpinnerContainer {
  height: 56px;
  width: 58.7px;
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.onboardingAttachmentCancelSpinner {
  --color: var(--cta-red);
  width: 24px;
  height: 24px;
}

.onboardingAttachmentCancel {
  /* Container */
  height: 56px;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* Text */

  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  text-align: right;
  color: var(--cta-red);
  margin-left: 12px;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.onboardingOptInButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -10px;
  cursor: pointer;
  margin-bottom: 24px;
}

.onboardingCheckbox {
  height: 44px;
  width: 44px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.onboardingOptInText {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

#requestDeletionModal {
  --width: 334px !important;
  --height: 196px !important;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

#onboardingLoginModal ion-toolbar {
  padding-top: max(var(--ion-safe-area-top), var(--ion-statusbar-padding)) !important;
}

.requestDeletionModalContent {
  --width: 334px !important;
  --height: 196px !important;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 900px) {
  .onboardingLoginContent {
    padding-top: 24px;
  }

  .onboardingLoginHeader {
    font-size: 22px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36 !important;
    letter-spacing: -0.3px !important;
    color: var(--grey-90) !important;
    margin-bottom: 10px;
  }
}

@media (max-height: 800px) {
  .onboardingLoginContent {
    padding-top: 24px;
  }
}