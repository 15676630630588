@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@ionic/core/css/core.css";
@import "@ionic/react/css/normalize.css";
@import "@ionic/react/css/structure.css";
@import "@ionic/react/css/typography.css";

@layer components {
  .acc-underline {
    text-decoration: var(--accessibility-underline-links);
    text-decoration-color: currentColor;
  }

  .blue-list-item li a {
    color: var(--cta-blue);
    text-decoration: underline;
  }

  .tailwind-anchor a {
    color: var(--cta-blue);
    text-decoration: underline;
  }

  #delete-draft-modal {
    --width: 326px !important;
    --height: 240px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #delete-wellbeing-activity-modal {
    --width: 326px !important;
    --height: 240px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .draft-toast {
    --background: #c6f7c1;
    --height: 44px;
    --border-radius: 12px;
  }

  .draft-toast::part(button cancel) {
    background: url("../assets/images/Icon_Toast_Close@3x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    margin-right: 10px;
  }

  .draft-toast::part(icon) {
    background-image: url("../assets/images/Icon_Toast_Tick@3x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 12px;
    width: 24px;
    height: 24px;
  }

  .draft-toast::part(message) {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: #085333;
    margin-left: -4px;
  }

  .ai-feedback-toast {
    --background: var(--ai-purple);
    --height: 44px;
    --border-radius: 12px;
  }

  .ai-feedback-toast::part(icon) {
    display: none;
  }

  .ai-feedback-toast::part(button positive-feedback) {
    background-image: url('../assets/images/Icon_Thumb_Up_Outline@3x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
  }

  .ai-feedback-toast::part(button positive-feedback-selected) {
    background-image: url('../assets/images/Icon_Thumb_Up_Outline_Filled@3x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
  }

  .ai-feedback-toast::part(button negative-feedback) {
    background-image: url('../assets/images/Icon_Thumb_Down_Outline@3x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
  }

  .ai-feedback-toast::part(button negative-feedback-selected) {
    background-image: url('../assets/images/Icon_Thumb_Down_Outline_Filled@3x.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
  }

  .ai-feedback-toast::part(message) {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: var(--white);
    margin-left: -4px;
  }

  .debug-toggle {
    --track-background: #9c1919;
    --track-background-checked: theme("colors.green.500");
  }

  .program-data-update-toast {
    --background: theme("colors.grey-40");
    --height: 44px;
    --border-radius: 12px;
    --max-width: 210px;
  }

  .toast-200 {
    --max-width: 160px !important;
  }

  .program-data-update-toast::part(button cancel) {
    background: url("../assets/images/Icon_Toast_Close@3x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    margin-right: 10px;
  }

  .force-scroll-auto::part(scroll) {
    overflow-y: auto !important;
  }

  /* .program-data-update-toast::part(icon) {
    background-image: url("../assets/images/Icon_Toast_Tick@3x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 12px;
    width: 24px;
    height: 24px;
  } */

  .program-data-update-toast::part(message) {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
    margin-left: -36px;
    text-align: center;
    color: theme("colors.grey-90");
  }

  #empty-tag-modal {
    --width: 326px !important;
    --height: 140px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #evidence-view-toggle::part(content) {
    --width: 200px;
  }

  #evidence-view-toggle::part(arrow) {
    visibility: hidden;
  }

  .evidence-view-toggle-item {
    --background: transparent !important;
    --border-width: 0 !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --inner-padding-end: 0px !important;
    --inner-padding-end: 0 !important;
    --ion-safe-area-left: 0px !important;
    --ion-safe-area-right: 0px !important;
  }

  .evidence-view-toggle-item button {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .evidence-view-toggle-button {
    --border-radius: 16px;
    height: 12px !important;
    --background: #0c99b2 !important;
    --background-hover: #2293ac !important;
    --background-activated: #2293ac !important;
    --background-focused: #2293ac !important;
  }

  .evidence-view-toggle-button::part(native) {
    padding-left: 13.5px;
    padding-right: 4px;
  }

  .img-no-select {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .preceptor-description a {
    color: var(--cta-blue);
    text-decoration: underline;
    text-decoration-color: currentColor;
  }

  .preceptee-item-button {
    --background: transparent !important;
    --border-width: 0 !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --inner-padding-end: 0px !important;
    --inner-padding-end: 0 !important;
    --ion-safe-area-left: 0px !important;
    --ion-safe-area-right: 0px !important;
  }

  .preceptee-item-button button {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .primary-button {
    --border-radius: 8px !important;
    --background: var(--ion-color-primary, #3880ff) !important;
    --background-hover: var(--ion-color-primary-tint, #4c8dff);
    --background-activated: var(--ion-color-primary-shade, #3171e0);
    --background-focused: var(--ion-color-primary-shade, #3171e0);
  }

  .white-button {
    --border-radius: 8px !important;
    --background: var(--white);
    --background-hover: var(--grey-40) !important;
    --background-activated: var(--grey-50) !important;
    --background-focused: var(--grey-50) !important;
  }

  .grey-button {
    --border-radius: 8px !important;
    --background: var(--grey-10);
    --background-hover: var(--grey-40);
    --background-activated: var(--grey-50);
    --background-focused: var(--grey-50);
  }

  #wellbeing-swiper,
  #wellbeing-swiper .swiper-slide {
    height: auto !important;
  }

  .program-card-button {
    --border-radius: 8px !important;
    --background: var(--grey-10);
    --background-hover: var(--grey-40);
    --background-activated: var(--grey-50);
    --background-focused: var(--grey-50);
  }

  .program-card-button-cta {
    --border-radius: 10px !important;
    --background: var(--ion-color-primary, #3880ff) !important;
    --background-hover: var(--ion-color-primary-tint, #4c8dff);
    --background-activated: var(--ion-color-primary-shade, #3171e0);
    --background-focused: var(--ion-color-primary-shade, #3171e0);
  }

  .program-card-button-submitted {
    --background: #feebc8;
    --background-hover: #feebc8;
    --background-activated: #feebc8;
    --background-focused: #feebc8;
    opacity: 1 !important;
  }

  .program-card-button-approved {
    --background: #c6f7c1;
    --background-hover: #c6f7c1;
    --background-activated: #c6f7c1;
    --background-focused: #c6f7c1;
    opacity: 1 !important;
  }

  .program-certificate-button {
    cursor: pointer !important;
  }

  .program-certificate-button-wait {
    cursor: progress !important;
  }

  .program-certificate-button-disabled {
    cursor: not-allowed !important;
  }

  .program-skill-button {
    --border-color: #ffffff !important;
    --padding-start: 0px !important;
    --inner-padding-start: 0 !important;
  }

  .help-centre-button {
    display: flex;
    justify-content: center !important;
    /* box-shadow: inset 0 -1px 0 0 #ced4da; */
    --border-width: 0 !important;
    /* box-shadow: inset 0 -1px 0 0 #ced4da; */
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    /* --inner-padding-start: 16px !important; */
    --inner-padding-end: 0px !important;
    --inner-padding-end: 0 !important;
    padding-right: 0px !important;
  }

  .help-centre-button-no-border {
    --inner-border-width: 0px !important;
  }

  .help-centre-video {
    width: 100%;
    aspect-ratio: 1280 / 720;
    background: url("../assets/images/Loading_Indicator.gif") center center no-repeat;
    background-size: 32px 32px;
  }

  .program-skill-button::part(native) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    --inner-padding-start: 0px !important;
    --inner-padding-end: 0px !important;
    --padding-end: 0px !important;
  }

  .program-info-card-button::part(native) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    --inner-padding-start: 0px !important;
    --inner-padding-end: 0px !important;
    --padding-end: 0px !important;
    width: 100% !important;
  }

  #save-draft-modal {
    --width: 326px !important;
    --height: 254px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #submit-progress-check-modal {
    --width: 320px !important;
    --height: 288px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #submit-progress-check-modal-expand {
    --width: 320px !important;
    --height: 308px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #wellbeing-disclaimer-modal {
    --width: 320px !important;
    --height: 158px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #wellbeing-confirm-modal {
    --width: 320px !important;
    --height: 282px !important;
    --border-radius: 10px !important;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #progress-check-list-subtitle>p {
    font-size: calc(13px + var(--accessibility-font-size)) !important;
    font-weight: var(--accessibility-bold-text);
    font-stretch: normal;
    font-style: normal;
    line-height: var(--accessibility-line-height);
    letter-spacing: -0.2px;
    color: var(--grey-70);
    margin-top: 8px;
  }

  #progress-check-list-subtitle>p:first-child {
    margin-top: 4px !important;
  }

  .toast-content {
    padding-left: 8px !important;
  }

  .wellbeing-ion-range {
    --bar-height: 16px;
    --bar-border-radius: 100px;
    border-radius: 100px !important;
    --knob-size: 36px;
  }

  .wellbeing-ion-range::part(tick) {
    margin-top: 12px;
    width: 2px;
  }

  .wellbeing-ion-range::part(tick-active):first-child,
  .wellbeing-ion-range::part(tick-active):last-child {
    display: none;
  }

  .wellbeing-range-bar-orange::part(bar-active) {
    --bar-background-active: #ed8936;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
  }

  .wellbeing-range-bar-red::part(bar-active) {
    --bar-background-active: #ed4136;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
  }

  .wellbeing-range-bar-green::part(bar-active) {
    --bar-background-active: #48bb78;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
  }

  .wellbeing-ion-range::part(tick-active) {
    margin-top: 12px;
    width: 2px;
    background: var(--ion-color-step-900, #e6e6e6);
  }

  .wellbeing-ion-range::part(bar-active) {
    --bar-border-radius: 100px;
    border-radius: 100px;
  }

  .wellbeing-ion-range::part(label) {
    margin-bottom: 12px;
  }

  .wellbeing-ion-range:part(tick-active):first-child,
  .wellbeing-ion-range:part(tick-active):nth-child(0) {
    background: #000000;
    visibility: hidden;
  }

  .iframe-container {
    @apply w-full relative float-none clear-both h-0 pb-[56.25%];
  }

  .iframe-content {
    @apply absolute top-0 left-0 w-full h-full border-0 border-white;
  }

  .min-h-dvh-nav {
    min-height: calc(100dvh - 44px);
  }

  .pb-h-dvh-nav {
    padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
  }

  .sso-list-item {
    --background: transparent !important;
    --border-width: 0 !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --inner-padding-end: 0px !important;
    --inner-padding-end: 0 !important;
    --ion-safe-area-left: 0px !important;
    --ion-safe-area-right: 0px !important;
  }

  .evidence-card-item {
    --background: var(--white);
    --padding-start: 0 !important;
    --inner-padding-start: 0 !important;
    --inner-padding-end: 0 !important;
    --padding-end: 0 !important;
    padding: 0 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    box-shadow: inset 0 -1px 0 0 #ced4da !important;
    --border-width: 0 !important;
  }

  .ai-dictation-button {
    --border-radius: 10px !important;
    --border-width: 1px !important;
    --border-color: #FFFFFF !important;
    border-width: 1px !important;
    border-color: #FFFFFF !important;
    border-radius: 10px !important;
  }

  .ai-dictation-button::part(native) {
    padding: 0;
  }

  .ai-dictation-info-button::part(native) {
    padding: 0;
    --border-radius: 44px;
  }

  .ai-dictation-button {
    --border-radius: 10px !important;
    --border-width: 1px !important;
    --border-color: #FFFFFF !important;
    border-width: 1px !important;
    border-color: #FFFFFF !important;
    border-radius: 10px !important;
  }

  .ai-dictation-button::part(native) {
    padding: 0;
  }

  ai-dictation-info-button::part(native) {
    padding: 0;
  }

  #reflection-activity-description-input textarea,
  #evidence-part-textbox textarea {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  #reflection-activity-description-input,
  #evidence-part-textbox {
    --placeholder-color: var(--grey-80);
  }

  #date-range-picker,
  #certificate-date-picker,
  #activity-date-range-picker {
    --width: 340px !important;
  }

  #date-range-picker::part(content),
  #certificate-date-picker::part(content),
  #activity-date-range-picker::part(content) {
    border-radius: 10px !important;
  }

  .audit-tickbox-button {
    --background: var(--white);
    --border-color: #ffffff !important;
    --padding-start: 0px !important;
    --inner-padding-start: 0 !important;
    --inner-padding-end: 0 !important;
    --padding-end: 0 !important;
    padding: 0 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .audit-tickbox-button::part(native) .item-inner {
    padding-right: 0px !important;
  }

  .audit-tickbox-button::part(native) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    --inner-padding-start: 0 !important;
    --inner-padding-end: 0 !important;
    --padding-end: 0 !important;
  }

}